import Container from 'typedi';
import { Component, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex';
import GtrSuper from '../../components/mixins/gtr-super.mixin';
import ErrorHandlerService from '../../services/error-handler.service';
import GtrStorage from '../../services/storage.service';
import moment from 'moment';

@Component({
	name: 'GtrEndUserLayout',
	computed: {
		...mapState('register', ['allContent']),
		...mapState('event', ['event']),
		...mapState('auth', ['participant', 'viewable_reg_pages'])
	}
})
export default class GtrEndUserLayout extends GtrSuper {
	viewable_reg_pages!: Array<string | number>;

	isInIframe = window.self !== window.top

    data() {
      return {
				event_identifier: this.$route.params.event_identifier,
				content_pages_sidebar: false,
				calledAllContent: false,
				currentLanguage: 'en',
				_allContent: {},
				_event: {},
				_participant: {},
				google_translate_loaded: false
      }
    }

	//#region Lifecycle
	async mounted() {
		try {
			this.$store.dispatch('common/showLoader', { value: true })
			const event_identifier = this.$route.params.event_identifier
			if (event_identifier) {
				await this.$store.dispatch('register/getAllContent', {
						event_identifier: event_identifier,
						reg_type: 'default'
				})

				const currentTimestamp = (new Date()).getTime()
				const startDate = moment.utc((this as any)?.allContent?.data?.orders_settings?.orders_start_date).valueOf()
				const endDate = moment.utc((this as any)?.allContent?.data?.orders_settings?.orders_end_date).valueOf()

				if (this.in_leads) {
					if (!(this as any)?.allContent?.data?.orders_settings?.orders_active) {
						this.$router.push({ name: 'leads.orders-closed' })
						return
					}
					if (!isNaN(startDate) && currentTimestamp < startDate) {
						this.$router.push({ name: 'leads.orders-closed' })
						return
					}
					if (!isNaN(endDate) && currentTimestamp > endDate) {
						this.$router.push({ name: 'leads.orders-closed' })
						return
					}
				}
			}

			if (event_identifier && Container.get(GtrStorage).getItem(`${event_identifier}_registration_access_token`)) {
				await this.$store.dispatch('auth/getParticipant', {
					event_identifier: event_identifier
				})
			}
			const $_GET: any = {}
			if (document.location.toString().indexOf('?') !== -1) {
				const query = document.location.toString().replace(/^.*?\?/, '').replace(/#.*$/, '').split('&')
				for (let i = 0, l = query.length; i < l; i++) {
					const aux = decodeURIComponent(query[i]).split('=')
					$_GET[aux[0]] = aux[1]
				}
			}
			if ($_GET['language']) {
				this.$data.currentLanguage = $_GET['language']
			}
			if (this.$store.state.register?.allContent?.data?.settings.first_page_override && this.$route.name?.indexOf('leads.') !== 0) {
				// If there is a first_page_override and we're not on a leads page, reroute to that page on first load.
				const pages = this.$store.state.register.allContent.data.pages
				const page = this.$store.state.register.allContent.data.settings.first_page_override
				const replacePage = pages[page]
				if (replacePage) {
					this.$router.push(`/${event_identifier}/register/pages/${replacePage.uuid}`)
				}
			}
			this.overrideLinkStyle(this.design.default_accent_body_text_color)
		} catch (error) {
			Container.get(ErrorHandlerService).error(error)
		} finally {
			this.$store.dispatch('common/hideLoader')
		}
	}
    //#endregion

	// override the vuetify generated stylesheet for html content.
	overrideLinkStyle (color: string | undefined) {
		/**
		 * if the color is undefined
		 * or is the default or
		 * isn't a hexadecimal code don't do anything.
		 * If this color comes from the backend it should always be a hexadecimal code.
 		 */
		const defaultColor = '#00333a'
		const hexPattern = /^#(?:[0-9A-Fa-f]{3}|[0-9A-Fa-f]{6}|[0-9A-Fa-f]{8})$/;
		if (!color || color.toLowerCase() === defaultColor || !hexPattern.test(color)) return

		// override the color
		const style = document.createElement('style')
		style.innerHTML = `
			.v-application a {
				color: ${color}
			}
		`
		document.head.appendChild(style)
	}

    //#region Watchers
	@Watch('allContent', { immediate: true })
	onAllContentChange (payload: any) {
		if (payload) {
			this.$data._allContent = payload
		}
	}

	@Watch('event', { immediate: true })
	onEventChange (payload: any) {
		if (payload) {
			this.$data._event = payload
			if (payload?.favicon && document.getElementById('favicon')) {
				(document.getElementById('favicon') as any).href = payload.favicon
			}
		}
	}

	@Watch('participant', { immediate: true })
	onParticipantChange (payload: any) {
		if (payload) {
			this.$data._participant = payload
		}
	}

	@Watch('settings')
	onSettingsChange (payload: any) {
		if (payload) {
			// Site Name
			window.document.title = payload.site_name && payload.site_name[this.$data.currentLanguage] ? payload.site_name[this.$data.currentLanguage] : process.env.VUE_APP_NAME

			// Favicon
			if (payload.favicon) {
				this.setFavicon(payload.favicon)
			}

			// Google Translate
			if (payload.google_translate_enabled && !this.$data.google_translate_loaded) {
				this.$data.google_translate_loaded = true;
				const googleTranslateScript: HTMLScriptElement = document.createElement('script')
				googleTranslateScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit')
				document.head.appendChild(googleTranslateScript)
			}
		}
	}
	//#endregion

	//#region Computed Props
	get backgroundColor() {
		return this.getValueOfProperty('background_color')
	}

	get backgroundImage() {
		const backgroundImage = this.getValueOfProperty('background_image')
		return backgroundImage !== '' && backgroundImage !== null ? `url(${encodeURI(backgroundImage)}) ` : ''
	}

	get headerImage() {
		const headerImage = this.getValueOfProperty('header_image')
		return headerImage !== '' && headerImage !== null ? `${headerImage}` : ''
	}

	get footerImage() {
		const footerImage = this.getValueOfProperty('footer_image')
		return footerImage !== '' && footerImage !== null ? `url(${footerImage})` : ''
	}

	get bodyFontColor () {
		return this.design.body_color ?? ''
	}

	get extraCss() {
		return this.getValueOfProperty('extra_css')
	}

	get headerContent() {
		return this.getValueOfProperty('header_content')
	}

	get footerContent() {
		return this.getValueOfProperty('footer_content')
	}

	get header_link() {
		return this.design.clickable_header && this.design.clickable_header_link ? this.design.clickable_header_link : ''
	}

	get isDev() {
		return window.location.href.includes('/dev/')
	}

	get fontFamily() {
		const fontFamily = this.getValueOfProperty('font_family')
		return fontFamily ?? ''
	}

	get show_content_pages() {
		return ((this.is_this_the_login_page && this.settings.show_content_pages_before_logging_in && Object.keys(this.content_pages).length > 0) || (!this.is_this_the_login_page && Object.keys(this.content_pages).length > 0)) && !this.in_leads && !this.in_surveys
	}

	get in_leads() {
		return this.$route.name?.indexOf('leads.') === 0
	}

	get in_surveys() {
		return this.$route.name?.indexOf('surveys.') === 0
	}

	get is_this_the_login_page() {
		return this.$route.name === 'registration.login.root'
	}

	get registration_page_name() {
		return this.settings.registration_page_name ? this.settings.registration_page_name[this.$data.currentLanguage] : 'Register'
	}

	get settings () {
		return this.$data._allContent.data ? this.$data._allContent.data.settings : {}
	}

	get design () {
		if (this.$data._allContent.data) {
			if (this.in_surveys) {
				return this.$data._allContent.data.evals_designSettings
			} else if (this.in_leads) {
				return this.$data._allContent.data.lr_designSettings
			} else {
				return this.$data._allContent.data.designSettings
			}
		}
		return {}
	}

	get template_data () {
		if (this.$data._allContent.data) {
			if (this.in_surveys) {
				return this.$data._allContent.data.evals_designSettings.template_data
			} else if (this.in_leads) {
				return this.$data._allContent.data.lr_designSettings.template_data
			} else {
				return this.$data._allContent.data.designSettings.template_data
			}
		}
		return {}
	}

	get option_groups () {
		return this.$data._allContent.data ? this.$data._allContent.data.option_groups : {}
	}

	get event () {
		return this.$data._allContent.data ? this.$data._allContent.data.event : {}
	}

	get tiers () {
		return this.$data._allContent.data ? this.$data._allContent.data.tiers : []
	}

	get content_pages () {
		return this.$data._allContent.data ? this.$data._allContent.data.pages_grouped.in_nav.sort((a: any, b: any) => {
			return parseInt(a.page_data.display_order) - parseInt(b.page_data.display_order)
		}) : []
	}

	get event_uuid() {
		return this.$data._allContent.data ? this.$data._allContent.data.event.uuid : {}
	}

	get current_registration_type () {
		return this.$data._participant.registration_type ? this.$data._participant.registration_type : '_default'
	}

	get template_name () {
		const result = this.design && this.design.template && this.design.template[this.current_registration_type]
			? this.design.template[this.current_registration_type].current_template
			: 'default'
		return result
	}

	get template1_background_image () {
		return this.design && this.design.template && this.design.template[this.current_registration_type].template_data.template1.background_image.value !== null
			? this.design.template[this.current_registration_type].template_data.template1.background_image.value
			: `${require('@/assets/img/bg-pricing.jpg')}`
	}
	//#endregion

	//#region Methods
	isTemplate(template: string) {
		const name = this.template_name
		return name === template
	}

	is_logged_in(): boolean {
		return !!Container.get(GtrStorage).getItem(`${this.$data.event_identifier}_registration_access_token`)
	}

	getValueOfProperty(prop: string) {
		let value = ''
		if (Object.keys(this.design).length > 0){
			Object.keys(this.design).forEach((key: any) => {
        const parts = key.split('_')
				if(parts.shift() === this.template_name) {
					const property = parts.join('_')
					if(property === prop) {
						value = this.design[key]
					}
				}
			})
		}
		return value
	}
	//#endregion
}
