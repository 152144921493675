import Container from 'typedi'
import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import FormBuilderService from '../services/formbuilder.service'
import router from '@/main'
import GtrStorage from '../services/storage.service'
import checkURLAndGo from "@/bootstrap/global/allowlist";

@Module({
    namespaced: true
})
export default class FormBuilderStore extends VuexModule {
    form: any = {}
    finishedPayment = false
    save_and_finish_clicked = false

    @Mutation
    SET_FORM(payload: any) {
		    this.form = payload
    }

    @Mutation
    SET_SAVE_AND_FINISH_CLICKED(payload: any) {
		this.save_and_finish_clicked = payload
    }

    @Mutation
    SET_FINISHED_PAYMENT(payload: any) {
		this.finishedPayment = payload
    }

    @Action({ rawError: true })
    async getForm (payload: any) {
        const {event_identifier, page_number, language } = payload
        let languageAddOn = ''
        if (language) {
            languageAddOn = `/?language=${language}`
        }
        const response = await Container.get(FormBuilderService).getForm(event_identifier, page_number, languageAddOn)
        this.context.commit('SET_FORM', response.data)
        return response
    }

    @Action({ rawError: true })
    async submitPayment (payload: any) {
        const { event_identifier, widget_id, transaction_type } = payload
        delete payload.event_identifier
        delete payload.page_number
        delete payload.currentLanguage
        const response = await Container.get(FormBuilderService).submitPayment(event_identifier, widget_id, transaction_type, payload)
        this.context.commit('SET_FINISHED_PAYMENT', true)
        return response
    }

    @Action({ rawError: true })
    async submitForm (payload: any) {
        const event_identifier = payload.event_identifier
        const pay_for_another  = payload.pay_for_another
        const page_number = payload.page_number
        const currentLanguage = payload.currentLanguage
        const isGroupRegistrationAndPayForAnotherYes = pay_for_another && pay_for_another === 'Yes'
        const isDev = payload.isDev
        delete payload.event_identifier
        delete payload.isDev
        delete payload.page_number
        delete payload.currentLanguage
        delete payload.last_viewable_reg_page
        delete payload.goToReceiptPage
        const response = await Container.get(FormBuilderService).submitForm(event_identifier, page_number, payload)
        const next_page = response.data.next_page // eslint-disable-line @typescript-eslint/no-unused-vars
        const baseUrl = '/' + (isDev ? 'dev/' : '') + event_identifier + '/'
        let languageQueryString = '' // eslint-disable-line @typescript-eslint/no-unused-vars
        if (currentLanguage !== 'en') {
            languageQueryString = '?language=' + currentLanguage
        }
        /**
        * Check if we are on the group registration
        * If we are, save the group uuid and then send them back to the login page (handled in register.js route)
        isGroupRegistrationAndPayForAnotherYes	 */
        if (isGroupRegistrationAndPayForAnotherYes) {
            /**
             * If there is already a group_uuid, don't create a group registration and just send them to the login page
             */
            if (!Container.get(GtrStorage).getItem(`${event_identifier}_group_uuid`)) {
                const response = await this.context.dispatch('register/createGroupRegistration', { event_identifier }, { root: true })
                if (router && response) {
                    checkURLAndGo(baseUrl + 'group') //forces reload of settings
                }
            } else {
                if (router) {
                    checkURLAndGo(baseUrl + 'group') //forces reload of settings
                }
            }
        } else {
            if (router) {
                router.push(baseUrl + 'register/' + next_page + languageQueryString)
            }
        }
        return response
    }

    @Action({ rawError: true })
    async cancelReg (payload: any) {
        const {event_identifier, isDev, data} = payload
        const response = await Container.get(FormBuilderService).cancelReg(event_identifier, data)
        router.push('/' + (isDev ? 'dev/' : '') + event_identifier)
        return response
    }

    @Action({ rawError: true })
    async transferReg (payload: any) {
        const { event_identifier, isDev, data } = payload
        const response  = await Container.get(FormBuilderService).transferReg(event_identifier, data)
        router.push('/' + (isDev ? 'dev/' : '') + event_identifier)
        return response
    }
}
