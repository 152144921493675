import Vue from 'vue'

Vue.filter('numberWithCommas', function (value: any) {
    if (value || value === 0) {
        const parts = value.toString().split('.')
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        return parts.join('.')
    }
})

Vue.filter('formatNumbers', function (value: any) {
    if (value) {
        const val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    }
})

Vue.filter('toCurrency', function (value, currency = 'usd') {
    if (typeof value !== 'number') {
        return value
    }
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    })
    return formatter.format(value) + (currency === 'cad' ? 'CAD' : '')
})
Vue.filter('capitalizeAndRemoveUnderscores', function (value) {
    if (typeof value !== 'string') {
        return value
    }
    const arrayOfWords = value.split('_')
    if (!arrayOfWords.length) {
        return value
    }
    return arrayOfWords.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
})

/**
 * @param {string} value - date to be formatted
 * returns formatted date - Example -> 02-09-2023, 02:29:25 PM EST
 */
Vue.filter('formatDateTimeTimeZone12Hr', function (value: string, timezone: string) {
    if (value) {
        let dateTimeStr: string
        value.toString().indexOf('Z') === -1 ? dateTimeStr = value + 'Z' : dateTimeStr = value
        return new Date(dateTimeStr).toLocaleTimeString('en-us', {
            hour12: true,
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: timezone,
            timeZoneName: 'short'
        }).replaceAll('/', '-')
    }
})
